//ヘッダをfixedにしていることで生じるアンカーリンクの誤差修正
$(function () {
  var headerHight = 0; //ヘッダの高さ
  $('a[href^=#]').click(function(){
      var href= $(this).attr("href");
      var target = $(href == "#" || href == "" ? 'html' : href);
      var position = target.offset().top-headerHight; //ヘッダの高さ分位置をずらす
      $("html, body").animate({scrollTop:position}, 300, "swing");
      return false;
  });
});

$(function(){
  $('.icon, .nav-item').click(function(){
    $('.nav-header').toggleClass('is-open');
    $('.icon').toggleClass('is-open');
  });
});
